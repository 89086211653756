import { faChevronUp, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  Button, Collapse,
  Container,
  Dropdown,
  Image,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Offcanvas,
} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.svg';
import './Header.scss';

export const Header: React.FC = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const [showOptions, toggleOptions] = useState(false);

  return (
    <Navbar expand="sm" className={ 'p-2 pt-3 header-fonts' } sticky={'top'} bg={'white'}>
      <Container fluid={ 'sm' } className={ 'ms-2 me-2 p-0 max-width-100 align-items-center' }>
        <Navbar.Brand className={ 'mr-5 ms-1' } href={'/'}>
          <Image src={ Logo } width={ 100 }/>
        </Navbar.Brand>
        <Navbar.Toggle>
          <FontAwesomeIcon icon={ faEllipsis } />
        </Navbar.Toggle>
        {!location.pathname.includes('unsubscribe') && (
          <Navbar.Offcanvas placement={'start'}>
            <Offcanvas.Header closeButton className={'justify-content-end'} />
            <Offcanvas.Body className={'justify-content-end'}>
              <Nav>
                <Nav.Link href="/purpose" className={'nav-item mb-md-0 mb-3'} onClick={() => toggleOptions(false)}>
                  <span
                    className={`hover-underline-animation ${location.pathname.includes('purpose') ? 'active-link' : ''}`}
                  >
                    Purpose
                  </span>
                </Nav.Link>
                <Nav.Link href="/about" className={'nav-item mb-md-0 mb-3'} onClick={() => toggleOptions(false)}>
                  <span
                    className={`hover-underline-animation ${location.pathname.includes('features') ? 'active-link' : ''}`}
                  >
                    About
                  </span>
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        )}
      </Container>
    </Navbar>
  );
};
