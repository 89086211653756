import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const DataUse: React.FC<{elemRef: any}> = ({ elemRef }) => {

  const scrollToElement = () => {
    elemRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start'});
  }
  return (
    <>
      <Row className={ 'justify-content-center mt-5' }>
        <Col sm={ 6 } className={ 'text-justify' }>
          <h6><strong>IV. How We Use Personal Data</strong></h6>
          <div>
            How we use the Personal Data that we collect depends in part on how you choose to communicate with us,
            how you use our websites and interact with us, and any preferences you have communicated to us.
            In general, we use your Personal Data as is necessary to run our business and carry out our day-to-day
            activities. In addition to the uses identified elsewhere in this Privacy Policy, we may use your
            Personal Data to accomplish the following tasks (and we have done so during the 12 months preceding
            the effective date of this Privacy Policy):
          </div>
          <div className={ 'mt-3' }>
            <strong className={ 'me-1' }>
              For the purpose of communicating with you about our products and services and facilitate other
              interaction.
            </strong>
            We may use your Personal Data, such as contact data, Ancillary Data, and metadata, to send you
            transactional communications, notices, updates, security alerts, and administrative messages regarding
            our products and services that may be useful to you and your organization. We will respond to your
            questions, provide tailored communications based on your activity and interactions with us, and help
            you use our products and services effectively. We also use Administrator Data to communicate with you
            for various purposes, including to provide you with account updates (about your subscription, settings,
            security, billing, feature and product updates, technical issues, certifications, and other similar
            content). You cannot unsubscribe from non-promotional and transactional communications. For promotional
            communications, you may manage your communication preferences via your administrator settings, Intramark
            communities, or our subscription center (linked in the <span className={'link-text'} onClick={scrollToElement}>Your Information Choices</span> section below). In some
            situations, we may also share Administrator Data with authorized partners in order to provide you with
            information relevant to your purchase of Intramark or other entities’ tools that may connect to Intramark
            to meet your technical or organizational needs.
          </div>
          <div className={ 'mt-3' }>
            <strong className={ 'me-1' }>
              For the purpose of supporting safety, security, and manage operations.
            </strong>
            We use Personal Data, such as contact data, Ancillary Data and other metadata, about you and your use of
            our products, services, and offices to verify accounts and activity, monitor suspicious or fraudulent
            activity, assist our customers in their monitoring of suspicious or fraudulent activity, and identify
            violations of policies regarding the use of our products and services. We may also combine Ancillary
            Data with other data we receive for safety, security, and to manage our business operations. We also
            process Personal Data, such as contact data and health data, for security and operations management
            reasons, such as to register visitors to our offices and carry out related safety measures, including
            to manage non-disclosure agreements that visitors may be required to sign. We also use Administrator
            Data to provide our customers with the Intramark service, complete transactions, provide support and
            other service to the customer account, detect and prevent fraud, for audit and compliance purposes,
            and to comply with applicable law. Intramark physically and electronically monitors its offices
            with access monitoring software and tools, and has on-premise surveillance cameras in order to prevent
            unauthorized access to our offices and to protect employees, authorized visitors, and our property.
          </div>
          <div className={ 'mt-3' }>
            <strong className={ 'me-1' }>
              For the purpose of marketing our products and services.
            </strong>
            We use your Personal Data, such as contact data, Ancillary Data, and other metadata about how you use
            the products and services to send promotional communications that may be of specific interest to you
            and your organization, including by email and by displaying Intramark marketing communications on other
            organizations’ websites and applications, as well as on third-party platforms like Facebook, Twitter,
            and Google. These communications are aimed at encouraging engagement and maximizing the benefits that
            you and your organization can gain from Intramark’s products and services, including information about
            new products and features, survey requests, newsletters, and events that we think may be of interest to
            you and your organization.
          </div>
          <div className={ 'mt-3' }>
            <strong className={ 'me-1' }>
              For the purpose of analyzing, predicting, and improving results and operations.
            </strong>
            We use Personal Data to analyze and predict results (such as those arising from our sales and marketing
            efforts and product usage and consumption), improve the performance of our websites, products and services
            and customer support, identify potential customers, opportunities, and potential new product areas,
            ascertain trends, improve our websites’ functionality, improve our security, and provide us with general
            business intelligence, including through the use of machine learning technology. We may also combine the
            metadata and usage information collected from our websites with other information to help further the
            purposes described in the previous sentence.
          </div>
          <div className={ 'mt-3' }>
            <strong className={ 'me-1' }>
              For the purpose of analyzing, predicting, and improving results and operations.
            </strong>
            We use Personal Data to analyze and predict results (such as those arising from our sales and marketing
            efforts and product usage and consumption), improve the performance of our websites, products and services
            and customer support, identify potential customers, opportunities, and potential new product areas,
            ascertain trends, improve our websites’ functionality, improve our security, and provide us with general
            business intelligence, including through the use of machine learning technology. We may also combine the
            metadata and usage information collected from our websites with other information to help further the
            purposes described in the previous sentence.
          </div>
          <div className={ 'mt-3' }>
            <strong className={ 'me-1' }>
              For the purpose of managing contests or promotions and customer appreciation.
            </strong>
            Intramark may occasionally run contests or other special promotions or make available opportunities or
            other tokens of appreciation for customers, and if you register for these, we may process your Personal
            Data, such as contact information, biographical information, and contract-related data to perform our
            contract with you. Intramark may also use the Personal Data, such as contact data, collected in these
            contests, promotions, and for customer appreciation as well as to send you gifts or prizes and promotional
            material about Intramark and our partners.
          </div>
          <div className={ 'mt-3' }>
            <strong className={ 'me-1' }>
              For the purpose of processing payments.
            </strong>
            Intramark may occasionally run contests or other special promotions or make available opportunities or
            other tokens of appreciation for customers, and if you register for these, we may process your Personal
            Data, such as contact information, biographical information, and contract-related data to perform our
            contract with you. Intramark may also use the Personal Data, such as contact data, collected in these
            contests, promotions, and for customer appreciation as well as to send you gifts or prizes and promotional
            material about Intramark and our partners.
          </div>
          <div className={ 'mt-3' }>
            <strong className={ 'me-1' }>
              For the purpose of recruiting and hiring.
            </strong>
            We process your Personal Data, such as contact, job applicant, and biographical data, to assess your
            application and to evaluate and improve our recruitment system, our application tracking and recruitment
            activities. We also use your Personal Data to communicate with you regarding your application or
            opportunities at Intramark that appear over time that we believe may be of interest to you. We also use
            your Personal Data to send you new hire and employee experience information. We may verify your
            information, including through reference checks and, where allowed, background checks.
          </div>
          <div className={ 'mt-3' }>
            <strong className={ 'me-1' }>
              Other purposes for our legitimate interests:
            </strong>
            We process your Personal Data, such as contact, job applicant, and biographical data, to assess your
            application and to evaluate and improve our recruitment system, our application tracking and recruitment
            activities. We also use your Personal Data to communicate with you regarding your application or
            opportunities at Intramark that appear over time that we believe may be of interest to you. We also use
            your Personal Data to send you new hire and employee experience information. We may verify your
            information, including through reference checks and, where allowed, background checks.
          </div>
          <div className={ 'mt-3' }>
            <strong className={ 'me-1' }>
              Other purposes with your consent:
            </strong>
            We process your Personal Data, such as contact, job applicant, and biographical data, to assess your
            application and to evaluate and improve our recruitment system, our application tracking and recruitment
            activities. We also use your Personal Data to communicate with you regarding your application or
            opportunities at Intramark that appear over time that we believe may be of interest to you. We also use
            your Personal Data to send you new hire and employee experience information. We may verify your
            information, including through reference checks and, where allowed, background checks.
          </div>
          <div className={ 'mt-3' }>
            If we process your Personal Data for a purpose other than those set out above, we will provide you with
            information prior to such processing.
          </div>
        </Col>
      </Row>
      <Row className={ 'justify-content-center mt-5' }>
        <Col sm={ 6 } className={ 'text-justify' }>
          <h6>
            <strong>
              Legal Bases for Processing Personal Data (for United Kingdom and European Economic Area and other
              relevant jurisdictions)
            </strong>
          </h6>
          <div>
            If you are an individual in the United Kingdom, the European Economic Area (EEA), or of another relevant
            jurisdiction, we collect and process information about you only where we have a legal basis or bases for
            doing so under applicable laws. The legal bases depend on the products and services that your organization
            has purchased from Intramark, how such products and services are used, and how you choose to interact and
            communicate with Intramark’s websites, systems, and whether you attend Intramark events. This means we
            collect and use your Personal Data only where:
          </div>
          <div className={ 'mt-3' }>
            <ul>
              <li>
                We need it to operate and provide you with our products and services, provide customer support and
                personalized features, and to protect the safety and security of our products and services;
              </li>
              <li className={ 'pt-1' }>
                It satisfies a legitimate interest of Intramark’s (which is not overridden by your data protection
                interests and rights), such as for research and development, to provide information to you about our
                products and services that we believe you and your organization may find useful, and to protect our
                legal rights and interests;

              </li>
              <li className={ 'pt-1' }>
                You give us consent to do so for a specific purpose; or
              </li>
              <li className={ 'pt-1' }>
                We need to comply with a legal obligation.
              </li>
            </ul>
          </div>
          <div className={ 'mt-3' }>
            If you have consented to our use of Personal Data about you for a specific purpose, you have the right to
            change your mind at any time, but this will not affect any processing that has already taken place. Where
            we are using your Personal Data because we or another entity (for example, your employer) have a
            legitimate interest to do so, you have the right to object to that use; however, in some cases, this may
            mean that you no longer use our products and services.
          </div>
          <div className={ 'mt-3' }>
            In the event that we de-identify any Personal Data for further use, we commit to maintain and use the
            information in de-identified form and will not attempt to re-identify the information, except for the
            purpose of determining if our de-identification processes satisfy applicable legal requirements.
          </div>
        </Col>
      </Row>
    </>
  )
}