import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { InlineWidget } from 'react-calendly';
import { useLocation } from 'react-router-dom';
import { Header } from '../../components/Header/Header';

export const ScheduleMeetingScreen: React.FC = () => {
  const { state } = useLocation();

  const url = "https://calendly.com/dstfleur-intramark/30min";

  return (
    <section>
      <Header />
      <section>
        <Container>
          <Row>
            <Col sm={12} className={'h-100vh'}>
              <InlineWidget
                url={url}
                styles={{
                  height: '100%'
                }}
                pageSettings={{
                  backgroundColor: '#FFFFFF'
                }}
                prefill={{
                  email: state.email,
                  name: state.name
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  )
}
