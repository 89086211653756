import React, { useEffect, useState } from 'react';
import './HomeScreen.scss';
import { Button, Col, Container, Form, Image, InputGroup, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header/Header';
import { Subscribe as SubscribeModel } from '../../models/Subscribe';
import SubscribeService from '../../services/SubscribeService';
import { kpi } from '../../utils/images';

export const HomeScreen: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [showSuccess, toggleShowSuccess] = useState(false);
  const [showError, toggleShowError] = useState(false);

  const [validated, setValidated] = useState(false);
  const [titleMessage, setTitleMessage] = useState("Ensure program efforts and member goals stay aligned in real-time.");
  const [subTitleMessage] = useState("Tech-driven process to close gaps, minimize disruptions, and maximize outcomes.");
  const [fadeClass, setFadeClass] = useState('fade-in'); // Track fade-in animation

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeClass(''); // Remove fade-in class before message change
      setTimeout(() => {
        if (titleMessage === "Ensure program efforts and member goals stay aligned in real-time.") {
          setTitleMessage("Ensure team efforts and LoB goals stay aligned in real-time.");
        } else {
          setTitleMessage("Ensure program efforts and member goals stay aligned in real-time.");
        }
        setFadeClass('fade-in'); // Reapply fade-in class after message change
      }, 50); // Timeout to allow DOM update before applying fade
    }, 5000);

    return () => clearInterval(interval);
  }, [titleMessage]);

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      await subscribeToUpdates();
    }
  }

  const subscribeToUpdates = async () => {
    try {
      const subscription: SubscribeModel = {
        email
      };
      await SubscribeService.saveSubscription(subscription);
      toggleShowSuccess(true);
      setTimeout(() => {
        toggleShowSuccess(false);
      }, 4000);

      setEmail('');
      setValidated(false);
    } catch (error) {
      toggleShowError(true);
      setTimeout(() => {
        toggleShowError(false);
      }, 4000);
    }
  }

  return (
    <section>
      <Header/>
      <section>
        <Container className={'main-container'}>
          <Row>
            <Col md={5} sm={12}>
              <Row className={'text-start'}>
                <Col sm={12} className={'font-bold'}>
                  <h1 className={fadeClass}>{titleMessage}</h1>
                  <h5 className={fadeClass}>{subTitleMessage}</h5>
                </Col>
                <Col sm={ 12 } className={ 'font-bold margin-top-100 justify-content-center d-flex flex-column text-center' }>
                  <Button variant="primary" className="btn-block slide-left-right p-3" id="button-addon2"
                          onClick={ () => navigate('/contact') }>
                    Book a demo
                  </Button>
                  <span className={'font-normal mt-1 slide-left-right'}>Let’s talk!... fit for your organization, partnership, PR.</span>
                </Col>
              </Row>
            </Col>
            <Col md={ 7 } sm={ 12}>
              <Image
                src={ kpi }
                width={'auto'}
                height={'100%'}
                className={'slide-right-left'}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  );
}
