import React from 'react';

import { Card, Col, Container, Image, Row } from 'react-bootstrap';

import { Header } from '../../components/Header/Header';

import './AboutScreen.scss';
import { dominique, kpi } from '../../utils/images';

export const AboutScreen: React.FC = () => {

  return (
    <section>
      <Header/>
      <section>
        <Container className={ 'purpose-container' }>
          <Row className={ 'top-container align-items-center purpose-fade-in' }>
            <Col sm={ 12 } className={ 'font-primary d-flex justify-content-start' }>
              <h1>
                About
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={ 'purpose-fade-in' }>
        <Container className={ 'pb-5' }>
          <Row className={ 'mt-4' }>
            <Col xs={ 12 } sm={7} className={ 'bg-light text-start p-4 d-flex flex-column h-100'}>
              <h3>
                Our strong foundation
              </h3>
              <div className={'mt-4'}>
                Intramark<span className={ 'font-primary' }>^</span> began as a bold idea fueled by the conviction
                that <i>'People need this!'</i>&nbsp;
                Today, we are proud to call some of the most reputable organizations our customers — a journey made possible only through the dedication and expertise of an incredible team.
              </div>
              <div className={'mt-5'}>
                <strong>To our valued customers:</strong> We are committed to delivering excellence, consistently. Your trust drives us to continuously innovate and exceed expectations. Together, we’re building something truly impactful.
              </div>
              <div className={'mt-5'}>
                <strong>Thank You!</strong>
              </div>
              <div>
                Sincerely,
                <br/>
                Dominique St-Fleur
                <br/>
                Founder, CEO<span className={ 'font-primary' }>^</span>
              </div>
            </Col>
            <Col xs={ 12 } sm={1}></Col>
            <Col xs={ 12 } sm={4} className={'d-flex flex-column mt-5 mt-sm-0'}>
              <Col>
                <Image src={ dominique } width={'80%'} />
                <h3 className={ 'font-primary mt-3' }>Dominique St-Fleur</h3>
                <h5>Chief Executive</h5>
                <div>
                  Business and Product Strategy
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  )
}