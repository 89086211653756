import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import { Header } from '../../components/Header/Header';

import './PurposeScreen.scss';

export const PurposeScreen: React.FC = () => {

  return (
    <section>
      <Header/>
      <section>
        <Container className={ 'purpose-container' }>
          <Row className={ 'top-container align-items-center purpose-fade-in' }>
            <Col sm={ 12 } className={ 'font-primary d-flex justify-content-start' }>
              <h1>
                Purpose
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={ 'purpose-fade-in' }>
        <Container className={ 'pb-5' }>
          <Row className={ 'mt-4' }>
            <Col sm={ 12 } className={ 'text-start' }>
              <h3>
                We started Intramark<span className={ 'font-primary' }>^</span> to solve a common problem that limits
                progress: the misalignment that forms when
                important information is missed or acted on too late.
              </h3>
            </Col>
            <Col sm={ 12 } className={ 'text-start mt-4' }>
              <h3>
                Intramark<span className={ 'font-primary' }>^</span> creates trusted processes that reliably lead to
                better results for everyone involved, helping people and organizations thrive.
              </h3>
            </Col>
            <Col sm={ 12 } className={ 'text-start mt-4' }>
              <h3>
                Positive outcomes include increased effectiveness and personal growth for individuals like customers,
                employees, leaders, students, and community members.
              </h3>
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  )
}