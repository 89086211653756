import React from 'react';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';

import './Footer.scss';
import { useLocation, useNavigate } from 'react-router-dom';

export const Footer: React.FC = () => {

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Navbar className={'p-2 footer footer-fonts align-items-center'} expand={'sm'}>
      <Container fluid={'sm'} className={'ms-2 me-2 p-0 max-width-100 footer-container'}>
        <Navbar.Brand>
          <span className={'copyright-font'}>© 2023 Intramark Inc. All Rights Reserved.</span>
        </Navbar.Brand>
        <Navbar className={'justify-content-end'}>
          {/*<Nav>*/}
          {/*  <Nav.Link href="/privacy-policy" className={'nav-item'}>*/}
          {/*    <span className={`${location.pathname.includes('privacy-policy') ? 'active-link' : ''}`}> Privacy </span>*/}
          {/*  </Nav.Link>*/}
          {/*  <Nav.Link href="/terms" className={'nav-item'}>*/}
          {/*    <span className={`${location.pathname.includes('terms') ? 'active-link' : ''}`}> Terms </span>*/}
          {/*  </Nav.Link>*/}
          {/*</Nav>*/}
          {/*<Button*/}
          {/*  size={'sm'}*/}
          {/*  variant={'primary'}*/}
          {/*  className={''}*/}
          {/*  onClick={() => navigate('/contact')}*/}
          {/*>*/}
          {/*  Contact*/}
          {/*</Button>*/}
        </Navbar>
      </Container>
    </Navbar>
  )
}
